// Background Colour / Buttons
$color-blue: #4FC3F7;
$color-blueDark: #142F53;
$color-blueLight: #A9DDF7;

// Content Headline
$color-navy: #3B667B;
$color-navyDark: #37474F;

// Header Title
$color-yellow: #FEE27E;
$color-yellowDark: #FCCE3A;
$color-yellowLight: #FFECB0;

// Content / Text
$color-steel: #77848A;
$color-steelDark: #636F74;
$color-steelLight: #ADB5B9;

// Background
$color-background: #37474F;
$color-backgroundDark: #232937;
$color-backgroundLight: #4FC3F7;

// Background Alt
$color-backgroundAlt: #F3F3F4;
$color-backgroundAltDark: #E2E1DB;
$color-backgroundAltLight: #FAFBFC;

// Stroke
$color-stroke: #F3F3F4;
$color-strokeDark: #CDD1D3;

// Green
$color-green: #2ECC71;
$color-greenDark: darken($color-green, 10%);
$color-greenLight: lighten($color-green, 10%);

// Forms
$color-red: #F22F70;