@import "colours";
@import "mixins";



////// COMMON

$maxWidth: 1200px;
$borderRadius: 15px;



////// TRANSITIONS

$easeOutExpo: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.320, 1.275);
$easeOutQuart: cubic-bezier(0.165, 0.840, 0.440, 1.000);



////// FONTS

//// FONT FAMILIES

$fontStack-chinese: "Microsoft Yahei", SimHei, "黑体", STXihei, "华文细黑", sans-serif;
$fontStack-english: Helvetica, Arial, Verdana, $fontStack-chinese;
$fontStack-primary: "Nunito", $fontStack-english;
$fontStack-secondary: "Open Sans", $fontStack-english;

//// FONT SIZES AND LINE HEIGHTS

// Titles
$fontSize-extraLarge: 32px;
$fontSize-extraLarge-lineHeight: 40px;

// Headers
$fontSize-large: 22px;
$fontSize-large-lineHeight: 26px;

// Body text
$fontSize-normal: 16px;
$fontSize-normal-lineHeight: 20px;

// Footer links
$fontSize-small: 14px;
$fontSize-small-lineHeight: 18px;

// Footnotes
$fontSize-extraSmall: 12px;
$fontSize-extraSmall-lineHeight: 16px;

//// SILENT CLASSES

%text-extraLarge {
	font-size: $fontSize-extraLarge;
	line-height: $fontSize-extraLarge;
}

%text-large {
	font-size: $fontSize-large;
	line-height: $fontSize-large;
}

%text-normal {
	font-size: $fontSize-normal;
	line-height: $fontSize-normal;
}

%text-small {
	font-size: $fontSize-small;
	line-height: $fontSize-small;
}

%text-extraSmall {
	font-size: $fontSize-extraSmall;
	line-height: $fontSize-extraSmall;
}