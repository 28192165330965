@import "_sass/common";



/******* GLOBAL NOTICE *******/

.miscContent > h2,
.miscContent > h3{
	margin-top: 40px;
	margin-bottom: 20px;
}

.miscContent > h2:first-child,
.miscContent > h3:first-child{
	margin-top: 0;
}

.miscContent h4 {
	margin-top: 20px;
	margin-bottom: 10px;
}

.miscContent > p,
.miscContent > ul,
.miscContent > ol,
.miscContent > ul li,
.miscContent > ol li {
	margin-bottom: 10px;
	text-align: justify;
}

.miscContent > ul ul,
.miscContent > ul ol,
.miscContent > ol ol,
.miscContent > ol ul {
	margin-top: 10px;
}

.miscContent > p.indent,
.miscContent ul,
.miscContent ol {
	padding-left: 10px;
	list-style-position: outside;
}

.miscContent ul.blank,
.miscContent ol.blank {
	list-style: none;
}

.miscContent ul.letters,
.miscContent ol.letters {
	list-style-type: lower-alpha;
}

.miscContent ul.roman,
.miscContent ol.roman {
	list-style-type: lower-roman;
}

.miscContent ul.disc,
.miscContent ol.disc {
	list-style-type: disc;
}

.miscContent > p.indent,
.miscContent ul li,
.miscContent ol li {
	margin-left: 1em;
}
.miscContent > p.indent,
.miscContent ul.lineSign li,
.miscContent ol.lineSign li {
	list-style: none;
	margin-left: 0;
	&:before{
		content: '-';
		margin-right: 0.5em;
	}
}
.miscContent > ul > li:last-child,
.miscContent > ol > li:last-child {
	margin-bottom: 0;
}



/******* IMPORTANT NOTICE *******/

.miscContent.notice {
	max-width: 800px;
}

.miscContent.notice > p {
	margin-bottom: 40px;
}

.miscContent.notice > .lastUpdated {
	margin-bottom: 0;
	color: $color-steelLight;
}



/******* PROMOTIONS OTHER *******/

.miscContent.promotionsOther ul li {
	margin-top: 10px;
	margin-bottom: 0;
}

.miscContent.promotionsOther ul li:last-child {
	margin-top: 0;
}

.miscContent.promotionsOther ul li a {
	color: inherit;
}

.miscContent.promotionsOther .promotionsOther-images {
	margin-top: 40px;
}

.miscContent.promotionsOther .promotionsOther-images img {
	margin: 0 auto 20px auto;
	width: 100%;
	max-width: 400px;
}



/******* IMAGE PROMOTION *******/

.imagePromotion {
	position: relative;
	max-width: 930px;
}

.imagePromotion.systemMessage {
	padding-top: 90px + 40px + 80px;
}

.imagePromotion .image {
	margin: 20px auto 40px auto;
	position: relative;
}

.imagePromotion .image .zoom {
	z-index: 10;
	position: absolute;
	right: 10px;
	bottom: 10px;
	display: none;
	width: 50px;
	height: 50px;
	border-radius: 10px;
	border: 2px solid rgba(0, 0, 0, .5);
	background: url("../../img/promotions/imagePromotion-zoom.svg") center center no-repeat rgba(255, 255, 255, .75);
}

.imagePromotion .image img {
	z-index: 0;
	position: relative;
	display: block;
	width: 100%;
	height: auto;
}

.imagePromotion h3 {
	margin-bottom: 10px;
	padding: 8px 0 8px 0;
	color: #FFFFFF;
	text-align: center;
	background: $color-blue;
}

.imagePromotion .video {
	display: block;
	width: 100%;
}



/******* 711 PROMOTION *******/

.columns-711 {
	margin-top: 20px;
	display: table;
	width: 100%;
	table-layout: fixed;
}

.columns-711 .column {
	display: table-cell;
	margin: 0;
	width: 100%;
	vertical-align: middle;
}

.columns-711 .column h3 {
	padding-left: 8px;
	padding-right: 8px;
	font-size: 18px;
	line-height: 18px;
}

.columns-711 .column.arrow {
	width: 60px;
}

.columns-711 .column.arrow:before {
	content: "";
	margin: 0 auto 0 auto;
	display: block;
	width: 100%;
	height: 40px;
	background: url('../../img/promotions/topup-711/topup711-arrow.svg') center center no-repeat transparent;
	background-size: contain;
}



/******* CYBERPORT *******/

.cyberport-grid {
	margin: 0 auto 0 auto;
	padding: 80px 40px 80px 40px;
	max-width: 1000px;
}

.cyberport-grid:after {
	@include clearFix;
}

.cyberport-grid > .section {
	position: relative;
	float: left;
	width: floor(100% / 3);
	@include calc(width, "100% / 3");
	height: 120px;
	color: #FFFFFF;
	text-decoration: none;
	@include transition(background-color 0.2s $easeOutQuart);
}

$locations: (
	"meifoo": #F15A29,
	"kowloontong": #2DA094,
	"olympic": #2DA094,
	"taiwai": #92278F,
	"hanghau": #4E913A,
	"yautong": #006838,
	"lamtin": #006838,
	"sheungwan": #B21A47,
	"saiwan": #B21A47,
	"kennedytown": #B21A47
);

@each $place, $colour in $locations {

	.cyberport-grid > .section.#{$place} {
		background-color: $colour;
	}

	.cyberport-grid > .section.#{$place}:hover,
	.cyberport-grid > .section.#{$place}:active {
		background-color: lighten($colour, 10%);
	}

}

.cyberport-grid > .section > .location,
.cyberport-grid > .section > .price {
	position: absolute;
	top: 50%;
	font-size: 24px;
	@include transform(translateY(-50%));
}

.cyberport-grid > .section > .location {
	left: 0;
	padding-left: 20px;
	line-height: 32px;
	text-align: left;
	font-weight: 700;
}

.cyberport-grid > .section > .price {
	right: 0;
	padding-right: 20px;
	line-height: 24px;
	text-align: right;
}
.cyberport-grid {
	.cyberport-grid-1F,
	.cyberport-grid-2F,
	.cyberport-grid-3F-left,
	.cyberport-grid-3F-right,
	.cyberport-grid-4F,
	.cyberport-grid-5F,
	.cyberport-grid-6F,
	.cyberport-grid-7F,
	.cyberport-grid-8F,
	.cyberport-grid-9F,
	.cyberport-grid-10F{
		display: flex;
		width: 100%;
		height: 120px;
		color: #FFFFFF;
		text-decoration: none;
		margin-bottom: 20px;
		span{
			flex: auto;
			line-height: 60px;
			text-align: center;
			font-size: 36px;
			&:last-child{
				font-size: 48px;
				font-weight: 700;
				flex: none;
				padding-right: 20px;
				line-height: 120px;
				text-align: right;
			}
		}
		&:hover{
			opacity: 0.8;
		}
	}
	.cyberport-grid-1F{
		background-color: #B21A47;
	}
	.cyberport-grid-2F{
		background-color:#983E1F
	}
	.cyberport-grid-3F{
		display: flex;
		.cyberport-grid-3F-left{
			width: 50%;
			background-color:#FF0000
		}
		.cyberport-grid-3F-right{
			width: 50%;
			border: 10px solid #FF0000;
			background-color: #2A3476;
			span{
				&:nth-child(1){
					line-height: 50px;
					flex: 2;
					margin-left: 50px;
				}
				&:nth-child(2){
					padding-right:10px;
					line-height: 100px;
				}
			}
		}
	}
	.cyberport-grid-4F{
		background-color:#2510AE
	}
	.cyberport-grid-5F{
		background-color:#4D4D4D
	}
	.cyberport-grid-6F{
		background-color:#B78900
	}
	.cyberport-grid-7F{
		background-color:#2DA094
	}
	.cyberport-grid-8F{
		background-color:#1358E2
	}
	.cyberport-grid-9F{
		background-color:#442565
	}
	.cyberport-grid-10F{
		background-color:#802A81
	}
}


/******* 404 *******/

.errorPage {
	background: $color-blue;
}

.errorPage > .wrapper {
	padding-top: 200px !important;
	padding-bottom: 100px !important;
}

.errorPage h1,
.errorPage h2 {
	text-align: center;
}

.errorPage h1 {
	margin-bottom: 10px;
	color: $color-yellow;
}

.errorPage h2 {
	margin-bottom: 30px;
	color: #FFFFFF;
}

.errorPage p {
	margin-bottom: 5px;
	text-align: center;
	color: #FFFFFF;
}

.errorPage p a {
	color: inherit;
}

.errorPage .back {
	margin: 30px auto 0 auto;
}

.errorPage .graphic {
	position: relative;
	margin: 40px auto 0 auto;
	width: 720px;
	height: 320px;
}

.errorPage .graphic .screen {
	z-index: 10;
	position: relative;
	margin: 0 auto 0 auto;
	width: 720px;
	height: 282px;
	background: url("../../img/system/404/404.png") no-repeat;
	background-size: contain;
}

.errorPage .graphic .screen .sweat {
	z-index: 10;
	position: absolute;
	right: 230px;
	top: 54px;
	width: 43px;
	height: 54px;
	background: url("../../img/system/404/sweat.png") no-repeat;
	background-size: contain;
}

.errorPage .graphic .tool {
	z-index: 0;
	position: absolute;
	background: center center no-repeat transparent;
	background-size: contain;
}

.errorPage .graphic .tool.tool-1,
.errorPage .graphic .tool.tool-2 {
	background-image: url("../../img/system/404/cog.svg");
}

.errorPage .graphic .tool.tool-1 {
	left: 130px;
	top: 10px;
	width: 92px;
	height: 92px;
}

.errorPage .graphic .tool.tool-2 {
	left: 80px;
	top: 100px;
	width: 136px;
	height: 136px;
}

.errorPage .graphic .tool.tool-3 {
	right: 50px;
	top: 0;
	width: 244px;
	height: 244px;
	background-image: url("../../img/system/404/spanner.svg");
}

.errorPage .graphic .tool.tool-1,
.errorPage .graphic .tool.tool-2 {
	@include animation (cogspin 18s linear infinite);
}

.errorPage .graphic .tool.tool-1 {
	-webkit-animation-direction: reverse;
	-moz-animation-direction: reverse;
	-ms-animation-direction: reverse;
	-o-animation-direction: reverse;
	animation-direction: reverse;
}

@-webkit-keyframes cogspin {
	0% { -webkit-transform: rotate(0deg) translateZ(0); }
	100% { -webkit-transform: rotate(359deg) translateZ(0); }
}

@-moz-keyframes cogspin {
	0% { -moz-transform: rotate(0deg) translateZ(0); }
	100% { -moz-transform: rotate(359deg) translateZ(0); }
}

@-ms-keyframes cogspin {
	0% { -ms-transform: rotate(0deg) translateZ(0); }
	100% { -ms-transform: rotate(359deg) translateZ(0); }
}

@-o-keyframes cogspin {
	0% { -o-transform: rotate(0deg) translateZ(0); }
	100% { -o-transform: rotate(359deg) translateZ(0); }
}

@keyframes cogspin {
	0% { transform: rotate(0deg) translateZ(0); }
	100% { transform: rotate(359deg) translateZ(0); }
}

.errorPage .graphic .screen .sweat {
	@include animation (sweat 3s forwards cubic-bezier(0.165, 0.840, 0.440, 1.000));
}

@-webkit-keyframes sweat {
	0% { -webkit-transform: translateY(0) translateZ(0); }
	25% { opacity: 1; }
	100% { opacity: 0; -webkit-transform: translateY(80px) translateZ(0); }
}

@-moz-keyframes sweat {
	0% { -moz-transform: translateY(0) translateZ(0); }
	25% { opacity: 1; }
	100% { opacity: 0; -moz-transform: translateY(80px) translateZ(0); }
}

@-ms-keyframes swaet {
	0% { -ms-transform: translateY(0) translateZ(0); }
	25% { opacity: 1; }
	100% { opacity: 0; -ms-transform: translateY(80px) translateZ(0); }
}

@-o-keyframes sweat {
	0% { -o-transform: translateY(0) translateZ(0); }
	25% { opacity: 1; }
	100% { opacity: 0; -o-transform: translateY(80px) translateZ(0); }
}

@keyframes sweat {
	0% { transform: translateY(0) translateZ(0); }
	25% { opacity: 1; }
	100% { opacity: 0; transform: translateY(80px) translateZ(0); }
}



/******* RESPONSIVE *******/

@media (max-width: $maxWidth) {

	.miscContent > h2 {
		margin-top: 20px;
		font-size: 14px;
		line-height: 16px;
	}

	.miscContent > p,
	.miscContent > ul,
	.miscContent > ol,
	.miscContent > ul li,
	.miscContent > ol li {
		margin-bottom: 5px;
	}

	.miscContent > ul ul,
	.miscContent > ul ol,
	.miscContent > ol ol,
	.miscContent > ol ul {
		margin-top: 5px;
	}

	.miscContent p,
	.miscContent li {
		font-size: 10px;
		line-height: 14px;
	}



	/******* IMPORTANT NOTICE *******/

	.miscContent.notice > p {
		margin-bottom: 20px;
	}



	/******* IMAGE PROMOTION *******/

	.imagePromotion .image {
		margin: 10px auto 20px auto;
	}

	.imagePromotion h3 {
		margin-bottom: 5px;
		padding: 4px 0 4px 0;
	}

	.imagePromotion .image .zoom {
		display: block;
	}



	/******* 711 PROMOTION *******/

	.columns-711 {
		display: block;
	}

	.columns-711 .column {
		margin-bottom: 10px;
		display: block;
	}

	.columns-711 .column:last-child {
		margin-bottom: 0;
	}

	.columns-711 .column h3 {
		font-size: 16px;
		line-height: 16px;
	}

	.columns-711 .column.arrow {
		width: 100%;
	}

	.columns-711 .column.arrow:before {
		margin: 0 auto 0 auto;
		@include transform (rotate(90deg));
	}



	/******* CYBERPORT *******/

	.cyberport-grid {
		padding: 50px 0 0 0;
	}

	body.systemMessage .cyberport-grid {
		padding-top: 50px + 40px;
	}

	.cyberport-grid > .section,
	.cyberport-grid > .section > .location {
		@include transition(none);
	}

	.cyberport-grid > .section {
		padding: 20px;
		width: 50%;
		height: auto;
		border-bottom: 2px solid #FFFFFF;
	}

	.cyberport-grid > .section:nth-child(odd) {
		border-right: 1px solid #FFFFFF;
	}

	.cyberport-grid > .section:nth-child(even) {
		border-left: 1px solid #FFFFFF;
	}

	.cyberport-grid > .section:nth-last-child(1):nth-child(odd) {
		clear: both;
		width: 100%;
		border-right: none;
		border-bottom: 0;
	}

	.cyberport-grid > .section > .location,
	.cyberport-grid > .section > .price {
		font-size: 16px;
		line-height: 20px;
		@include transform(none);
	}

	.cyberport-grid > .section > .location {
		position: static;
		padding-left: 0;
	}

	.cyberport-grid > .section > .price {
		right: 20px;
		bottom: 20px;
		padding-right: 0;
	}
	.cyberport-grid{
		.cyberport-grid-1F,
		.cyberport-grid-2F,
		.cyberport-grid-3F,
		.cyberport-grid-4F,
		.cyberport-grid-5F,
		.cyberport-grid-6F,
		.cyberport-grid-7F,
		.cyberport-grid-8F,
		.cyberport-grid-9F,
		.cyberport-grid-10F{
			width: 95%;
			height: 90px;
			margin: 0 auto 20px;
			span{
				font-size: 18px;
				line-height: 45px;
				&:last-child{
					font-size: 26px;
					padding-right: 5px;
					line-height: 90px;
				}
			}
		}
		.cyberport-grid-3F{
			.cyberport-grid-3F-left{
				height: auto;
				margin-bottom: 0;
			}
			.cyberport-grid-3F-right{
				border: 5px solid #FF0000;
				height: auto;
				margin-bottom: 0;
				span{
					&:nth-child(1){
						line-height: 40px;
						margin-left: 0;
					}
					&:nth-child(2){
						padding-right:0;
						line-height: 90px;
					}
				}
			}
		}
	}


	/******* 404 *******/

	.errorPage > .wrapper {
		padding-top: 80px !important;
		padding-bottom: 50px !important;
	}

	.errorPage h2 {
		margin-bottom: 5px;
		font-size: 20px;
		line-height: 20px;
	}

	.errorPage h3 {
		margin-bottom: 20px;
		font-size: 14px;
		line-height: 14px;
	}

	.errorPage p {
		margin-bottom: 5px;
		font-size: 14px;
		line-height: 18px;
	}

	.errorPage .back {
		margin: 20px auto 0 auto;
	}

	.errorPage .graphic,
	.errorPage .graphic .screen {
		width: 280px;
	}

	.errorPage .graphic {
		height: 124px;
	}

	.errorPage .graphic .screen {
		height: 110px;
	}

	.errorPage .graphic .tool.tool-1 {
		left: 54px;
		top: -23px;
		width: 50px;
		height: 50px;
	}

	.errorPage .graphic .tool.tool-2 {
		left: 26px;
		top: 29px;
		width: 60px;
		height: 60px;
	}

	.errorPage .graphic .tool.tool-3 {
		right: 14px;
		top: 0;
		width: 100px;
		height: 100px;
	}

	.errorPage .graphic .screen .sweat {
		right: 95px;
		top: 20px;
		width: 14px;
		height: 18px;
	}

	@-webkit-keyframes sweat {
		0% { -webkit-transform: translateY(0) translateZ(0); }
		25% { opacity: 1; }
		100% { opacity: 0; -webkit-transform: translateY(40px) translateZ(0); }
	}

	@-moz-keyframes sweat {
		0% { -moz-transform: translateY(0) translateZ(0); }
		25% { opacity: 1; }
		100% { opacity: 0; -moz-transform: translateY(40px) translateZ(0); }
	}

	@-ms-keyframes swaet {
		0% { -ms-transform: translateY(0) translateZ(0); }
		25% { opacity: 1; }
		100% { opacity: 0; -ms-transform: translateY(40px) translateZ(0); }
	}

	@-o-keyframes sweat {
		0% { -o-transform: translateY(0) translateZ(0); }
		25% { opacity: 1; }
		100% { opacity: 0; -o-transform: translateY(40px) translateZ(0); }
	}

	@keyframes sweat {
		0% { transform: translateY(0) translateZ(0); }
		25% { opacity: 1; }
		100% { opacity: 0; transform: translateY(40px) translateZ(0); }
	}

}

/******* T & C *******/
.table{
	text-align: left;
	border-collapse:collapse;
	color: #000;
	thead {
		background: rgb(240, 240, 240);
		border-color: rgb(221, 221, 221);
	}
	td, th {
		border: 1px solid rgb(221, 221, 221);
		padding: 7px 15px 7px 10px;
		margin: 0;
		height: 41px;
	}
}

/******* cyberport-bus *******/
.p-margin{
	p{
		margin-bottom: 10px;
	}
}
